export const Wordle = () => {
  return (
    <div className="wordle-div">
<div style={{ width: "100%", height: "800px", border: "none" }}>
      <iframe
        src="https://lucianatux.github.io/wordle-game/"
        style={{ width: "100%", height: "100%", border: "none" }}
        title="Wordle"
      />
    </div>
    </div>
    
  );
};
